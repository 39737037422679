<template>
  <div>
    <div v-show="supplierDiv">
      <div class="apply_box_top">
        <div class="apply_box_top_left">
          <div class="apply_box_total">
            <div class="apply_box_total_icon">
              <i class="iconfont icon-walletY-fill"></i>
            </div>
            <div class="apply_box_total_text">
              <span>供应商总发票金额(元)</span>
              <el-popover placement="top" trigger="hover" :content="supplierObj.totalAccount|NumFormat" popper-class="popper_class_account">
                <div slot="reference" class="text_overflow1">{{ supplierObj.totalAccount|NumFormat }}</div>
              </el-popover>
            </div>
          </div>
        </div>
        <div class="apply_box_top_right">
          <div ref="totalMoneyEchart" class="echarts_box"></div>
        </div>
      </div>

      <div class="table_common">
        <!-- 搜索 -->
        <div class="search_flex_box">
          <div class="search_condition">
            <el-form ref="supplierSerForm" :model="supplierSerForm">
              <el-form-item label="供应商编号:" class="financing_border">
                <el-input v-model="supplierSerForm.supplierId" @input="searchInput1" maxlength="20"></el-input>
              </el-form-item>
              <el-form-item label="供应商名称:">
                <el-input v-model="supplierSerForm.supplierName"></el-input>
              </el-form-item>
              <el-form-item label="合作年限:">
                <el-select v-model="supplierSerForm.cooperYears" placeholder="" class="select_class">
                  <el-option v-for="item in options" :key="item.codeState" :label="item.value" :value="item.codeState">
                  </el-option>
                </el-select>
              </el-form-item>

            </el-form>
          </div>
          <div class="search_boxs flex_center_between_box">
            <el-tooltip popper-class="search" effect="light" content="搜索" placement="top">
              <div class="search_button_box" @click="searchForm()">
                <i class="iconfont icon-10sousuo"></i>
              </div>
            </el-tooltip>
            <el-tooltip popper-class="search" effect="light" content="重置" placement="top">
              <div class="reset_button_box" @click="clearSupSearch()">
                <i class="iconfont icon-shuaxin"></i>
              </div>
            </el-tooltip>
          </div>
        </div>
        <!-- 表格 -->
        <div class="table_box">
          <div class="table_box_top flex_center">
            <div class="table_box_top_title">我的供应商</div>
            <div class="flex_center_end_box">
              <div class="search_button button" @click="dialogAddPartner();dialogTit = '添加供应商';">
                添加供应商
              </div>
              <div class="search_button button data_export">数据导出</div>
            </div>
          </div>
          <div class="table_box_bottom">
            <el-table ref="treeTable" :data="treeTable" stripe border style="width: 100%" @expand-change="onExpandChange">
              <el-table-column type="expand" align="center" width="1">
                <template slot-scope="scope">
                  <div class="tree_table_box">
                    <div class="search_condition sub_search_condition flex_center_between_box">
                      <el-form ref="invSearchForm" :model="invSearchForm">
                        <el-form-item label="发票号:" class="financing_border select_class_sep" prop="invoiceNo">
                          <el-input v-model="invSearchForm.invoiceNo"></el-input>
                        </el-form-item>
                        <el-form-item label="发票日期:" prop="invoiceDate">
                          <el-date-picker v-model="invSearchForm.invoiceDate" :editable="false" :picker-options="applyDateChoose" value-format="yyyy-MM-dd" prefix-icon="iconfont icon-riqi"
                            type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" />
                        </el-form-item>
                        <el-form-item label="发票金额:" prop="invoiceAmount">
                          <el-input v-model="invSearchForm.invoiceAmount"></el-input>
                        </el-form-item>
                        <el-form-item label="发票付款状态:" prop="invoicePayState">
                          <el-select v-model="invSearchForm.invoicePayState" placeholder="" class="select_class special_select_class">
                            <el-option v-for="item in isConfirmOptions" :key="item.codeState" :label="item.value" :value="item.codeState">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-form>
                      <!-- <div class="button" @click="getInvList()">搜 索</div> -->
                      <div class="search_boxs flex_center_between_box">
                        <div class="search_button_box" @click="getInvList()">
                          <i class="iconfont icon-10sousuo"></i>
                        </div>
                        <div class="reset_button_box" @click="clearInvSearch()">
                          <i class="iconfont icon-shuaxin"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tree_table_box">
                    <el-table :data="childTable" stripe border :key="scope.row.id" style="width: 100%">
                      <el-table-column label="发票号" prop="invoiceNo" align="center" show-overflow-tooltip :resizable="false" />
                      <el-table-column label="发票日期" prop="invoiceDate" align="center" sortable show-overflow-tooltip :resizable="false" />
                      <el-table-column label="发票金额(元)" prop="invoiceAmount" align="right" sortable header-align="center" class-name="align_table" show-overflow-tooltip :resizable="false">
                        <template slot-scope="scope">
                          {{ scope.row.invoiceAmount | NumFormat }}
                        </template>
                      </el-table-column>
                      <el-table-column label="发票确认状态" prop="isApproval" align="center" show-overflow-tooltip :resizable="false">
                        <template scope="scope">
                          <el-badge v-if="(scope.row.isApproval == 0) && (scope.row.highly == '1')" is-dot class="item">不认可</el-badge>
                          <div v-else-if="(scope.row.isApproval == 0) && (scope.row.highly == '0'||scope.row.highly == null)">不认可</div>
                          <div v-else-if="(scope.row.isApproval == 1) && (scope.row.highly == '0'||scope.row.highly == null)">认可</div>
                          <div v-else-if="(scope.row.isApproval == 1) && (scope.row.highly == '1')">认可</div>
                          <div v-else></div>
                        </template>
                      </el-table-column>
                      <el-table-column label="发票付款状态" prop="invoiceState" align="center" show-overflow-tooltip :resizable="false">
                        <template slot-scope="scope">
                          <div v-if="scope.row.invoiceState=='0'">未付款</div>
                          <div v-else>已付款</div>
                        </template>
                      </el-table-column>
                      <el-table-column label="提前付款折扣" prop="rate" align="center" show-overflow-tooltip :resizable="false" />
                      <el-table-column label="发票账期(天)" prop="accPeriod" align="center" sortable show-overflow-tooltip :resizable="false" />
                      <el-table-column label="账款到期日" prop="invoiceDateEnd" align="center" sortable show-overflow-tooltip :resizable="false" />
                      <el-table-column label="操 作" prop="operation" fixed="right" align="center" show-overflow-tooltip :resizable="false" :width="lastWidth">
                        <template slot-scope="scope">
                          <div class="table_oper flex_center el-width">
                            <el-tooltip v-if="scope.row.isApproval == 0 || scope.row.invoiceState=='0'||scope.row.invoiceState==null" class="item_color_money" effect="light" content="发票确认状态"
                              placement="top">
                              <i class="iconfont icon-queren" @click="invConfirmDialog(scope.row)"></i>
                            </el-tooltip>
                            <el-tooltip v-else class="item_color" effect="light" content="详情" placement="top">
                              <i class="iconfont icon-shuxingliebiaoxiangqing" @click="dialogInvDetail(scope.row)"></i>
                            </el-tooltip>
                            <el-tooltip class="item_color_money gray_color" effect="light" content="协助还款" placement="top" v-if="scope.row.financingNum == null ||scope.row.isApproval==0 ||scope.row.repaid==1 || scope.row.invoiceState==1">
                              <i class="iconfont icon-huankuantixing" ></i>
                            </el-tooltip>
                            <el-tooltip class="item_color_money" effect="light" content="协助还款" placement="top" v-else>
                              <i class="iconfont icon-huankuantixing" @click="dialogRepayment(scope.row)"></i>
                            </el-tooltip>
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                    <Page :totalFont="true" :total="childTotalPage" :currentPage='invSearchForm.pageNo' @onPageChange="onChildPageChange"></Page>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="供应商编号" prop="supplierId" align="center" show-overflow-tooltip :resizable="false" :width="suplNoWidth" class-name="financing_cursor">
                <template slot-scope="scope">
                  <span class="expans_icon" :class="{ expandIconExpanded: scope.row.isExpand }" @click="onClickExpansion(scope.row)"><i class="iconfont icon-youjiantou"></i>
                    {{ scope.row.supplierId }}</span>
                </template>
              </el-table-column>
              <el-table-column label="供应商名称" prop="supplierName" align="left" header-align="center" show-overflow-tooltip :resizable="false" class-name="financing_cursor">
                <template slot-scope="scope">
                  <div class="link" @click="detailIndex(scope.row)">{{scope.row.supplierName}}</div>
                </template>
              </el-table-column>
              <el-table-column label="供应商发票数量" prop="invoiceQty" show-overflow-tooltip align="right" header-align="center" sortable :resizable="false" :width="width170" />
              <el-table-column label="供应商联系人" prop="supplierPerson" align="center" header-align="center" show-overflow-tooltip class-name="align_table" :resizable="false" :width="suplWidth" />
              <el-table-column label="供应商电话" prop="supplierPhone" align="center" header-align="center" show-overflow-tooltip class-name="align_table" :resizable="false" />
              <el-table-column label="供应商邮箱" prop="supplierEmail" align="center" header-align="center" width="120" show-overflow-tooltip class-name="align_table" :resizable="false">
                <template slot-scope="scope">
                  <div class="noLink" v-if="scope.row.supplierEmail">
                    {{ scope.row.supplierEmail }}
                  </div>
                  <div v-else>暂无邮箱</div>
                </template>
              </el-table-column>
              <el-table-column label="合作年限" prop="cooperYears" align="center" show-overflow-tooltip :resizable="false" :width="width120">
                <template slot-scope="scope">
                  <div v-if="scope.row.cooperYears=='0'">新客户</div>
                  <div v-else-if="scope.row.cooperYears=='1'">1-3年</div>
                  <div v-else>3年以上</div>
                </template>
              </el-table-column>
              <el-table-column label="合作描述" prop="cooperDesc" align="center" show-overflow-tooltip :resizable="false">
                <template slot-scope="scope">
                  <div v-if="scope.row.cooperDesc">
                    {{ scope.row.cooperDesc }}
                  </div>
                  <div v-else>暂无描述</div>
                </template>
              </el-table-column>
              <el-table-column label="操 作" prop="operation" align="center" :resizable="false" fixed="right" :width="lastWidth">
                <template slot-scope="scope">
                  <div class="table_oper flex_center">
                    <el-tooltip class="item_color" effect="light" content="编辑" placement="top">
                      <i class="iconfont icon-bianji" @click="dialogEditPartner(scope.row)"></i>
                    </el-tooltip>
                    <el-tooltip class="item_color" effect="light" content="服务" placement="top">
                      <i class="iconfont icon--fuwu-xianxing"></i>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>

            </el-table>
          </div>
          <Page :totalFont="true" :total="faTotalPage" :currentPage='supplierSerForm.pageNo' @onPageChange="onPageChange"></Page>
        </div>
      </div>
    </div>
    <!-- 编辑和添加供应商弹框 -->
    <el-dialog :title="dialogTit" :visible.sync="applySupplierDialog" center class="dialog_width500 dialog_add_edit_form" :close-on-click-modal="false">
      <div class="add_invoice_message inner_common">
        <el-form :model="addPartner" ref="addPartner" :rules="addRules">
          <!-- <el-form-item label="供应商编号:" prop="supplierId">
            <el-input v-model="addPartner.supplierId" disabled></el-input>
          </el-form-item> -->
          <el-form-item label="供应商名称:" prop="supplierName">
            <!-- <el-autocomplete
              class="inline-input"
              v-model="addPartner.supplierName"
              :fetch-suggestions="querySearch"
              :trigger-on-focus="false"
              @select="handleSelect"
              >  <template slot-scope="{ item }">
                    <div class="name">{{ item.cnCompany }}</div>
                  </template>
              </el-autocomplete> -->
            <el-autocomplete class="inline-input" v-model="addPartner.supplierName" :fetch-suggestions="querySearch" :trigger-on-focus="true" @select="handleSelect">
            </el-autocomplete>
          </el-form-item>
          <el-form-item label="供应商联系人:" prop="supplierPerson">
            <el-input v-model="addPartner.supplierPerson" :disabled="disableInpt"></el-input>
          </el-form-item>
          <el-form-item label="供应商电话:" prop="supplierPhone">
            <el-input v-model="addPartner.supplierPhone" :disabled="disableInpt" maxlength="11"></el-input>
          </el-form-item>
          <el-form-item label="供应商邮箱:" prop="supplierEmail">
            <el-input v-model="addPartner.supplierEmail" :disabled="disableInpt"></el-input>
          </el-form-item>
          <el-form-item label="合作年限:" prop="cooperYears">
            <el-select v-model="addPartner.cooperYears" :disabled="disableInpt" placeholder="" @change="selChangeCooper">
              <el-option v-for="item in options" :key="item.codeState" :label="item.value" :value="item.codeState">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="合作信息描述:" prop="cooperDesc">
            <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 6 }" maxlength="200" show-word-limit v-model="addPartner.cooperDesc" :disabled="disableInpt">
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="footer_button2" round @click="cancelSupplierDialog()">取 消</el-button>
        <el-button class="footer_button1" type="primary" @click="submitSupplierForm('addPartner')">提 交</el-button>
      </span>
    </el-dialog>

    <!-- 发票确认弹框 -->
    <el-dialog :title="dialogInvTit" :visible.sync="applyInvDialog" center class="dialog_width500 dialog_add_edit_form width_1000" :close-on-click-modal="false">
      <el-form :model="invPartner" ref="invPartner" :rules="invRules" :key="refe">
        <div class="width_1000_left width_1000_auto">
          <div class="add_invoice_message inner_common">
            <el-form-item label="采购商名称:" prop="purchaseName">
              <el-input v-model="invPartner.purchaseName" :disabled="disableInvInpt" maxlength="8"></el-input>
            </el-form-item>
            <el-form-item label="发票号码:" prop="invoiceNo">
              <el-input v-model="invPartner.invoiceNo" :disabled="disableInvInpt" maxlength="8"></el-input>
            </el-form-item>
            <!-- <el-form-item label="发票金额:" prop="invoiceAmount">
              <el-input
                v-model="invPartner.invoiceAmount"
                :disabled="disableInvInpt"
              ></el-input>
            </el-form-item> -->
            <el-form-item label="发票金额:" prop="newInvoiceAmount">
              <el-input v-model="invPartner.newInvoiceAmount" :disabled="disableInvInpt"></el-input>
              <el-input v-model="invPartner.invoiceAmount" :disabled="disableInvInpt" class="hInpt"></el-input>
            </el-form-item>
            <el-form-item label="发票日期:" prop="invoiceDate">
              <el-input v-model="invPartner.invoiceDate" :disabled="disableInvInpt" :picker-options="applyDateChoose"></el-input>
            </el-form-item>
            <el-form-item label="发票账期:" prop="accPeriod">
              <el-input v-model="invPartner.accPeriod" :disabled="disableInvInpt" maxlength="3"></el-input>
            </el-form-item>
            <template v-if="!hasApproval">
              <el-form-item label="发票确认状态:" prop="isApproval">
                <el-select v-model="invPartner.isApproval" :disabled="disableInpt" class="select_class">
                  <el-option v-for="item in invconfirmOptions" :key="item.codeState" :label="item.value" :value="item.codeState">
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
            <el-form-item label="发票确认状态:" v-else>
              <el-input v-model="invPartner.isApprovalName" :disabled="disableInvInpt">
              </el-input>
            </el-form-item>
          </div>
        </div>
        <div class="width_1000_right width_1000_auto">
          <div class="add_invoice_message inner_common">
            <template v-if="hasNoPay">
              <el-form-item label="发票付款状态:" prop="invoiceState">
                <el-select v-model="invPartner.invoiceState" @change='changePayStatus()'>
                  <el-option v-for="item in isConfirmOptions" :key="item.codeState" :label="item.value" :value="item.codeState"></el-option>
                </el-select>
              </el-form-item>
            </template>
            <el-form-item label="发票付款状态:" prop="invoiceStateName" v-else>
              <el-input v-model="invPartner.invoiceStateName" :disabled="disableInvInpt"></el-input>
            </el-form-item>

            <el-form-item label="付款金额:" prop="newPaymentAmount" v-if="invBtnChange && isPayDisplay">
              <el-input v-model="invPartner.newPaymentAmount" maxlength="20" onkeyup='this.value=this.value.replace(/\D/gi,"")' @focus="inputPayFocus(invPartner.newPaymentAmount)"
                @blur="inputPayBlur(invPartner.newPaymentAmount)"></el-input>
            </el-form-item>

            <el-form-item label="付款金额:" v-else>
              <el-input v-model="invPartner.paymentAmount" :disabled="disableInvInpt"></el-input>
            </el-form-item>
            <el-form-item label="发票付款日期:" prop="paymentDate" v-if="invBtnChange && isPayDisplay">
              <el-date-picker v-model="invPartner.paymentDate" :editable='false' prefix-icon="iconfont icon-riqi" type="date" value-format="yyyy-MM-dd" :picker-options="applyDateChoose">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="发票付款日期:" v-else>
              <el-input v-model="invPartner.paymentDate" :disabled="disableInvInpt"></el-input>
            </el-form-item>
            <el-form-item label="上传付款凭证:" class="change_flex upload_item" prop="paymentVoucher" v-if="invBtnChange && isPayDisplay">
              <el-upload ref="uploadPayForm" :http-request="payUploadFile" :on-remove="payFileRemove" action="fakeaction" v-model="invPartner.paymentVoucher" class="upload_demo"
                :file-list="payFileList" list-type="picture" accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PBG">
                <div class="upload_box">
                  <i class="el-icon-plus"></i>
                </div>
                <span slot="tip" class="el-upload__tip">支持JPG、PNG格式，大小不超过15M</span>
              </el-upload>
            </el-form-item>
            <el-form-item label="采购商付款凭证:" class="change_width no_edit_width" v-else>
              <ul class="invimg_list flex_wrap">
                <li v-for="(item, index) in payUrlList" :key="index" class="flex_center">
                  <img :src="jpg + item.imgUrl" />
                </li>
              </ul>
            </el-form-item>
            <el-form-item label="买家是否已收货:" class="change_width no_edit_width" prop="isReceived" v-if="!invBtnChange">
              {{invPartner.isReceived=='1'?'是':'否'}}
            </el-form-item>
            <el-form-item label="买家是否已收发票:" class="change_width no_edit_width" prop="isInvoice" v-if="!invBtnChange">
              {{invPartner.isInvoice=='1'?'是':'否'}}
            </el-form-item>
            <el-form-item label="发票图片:" prop="invoiceUrl" class="change_width no_edit_width">
              <ul class="invimg_list flex_wrap">
                <li v-for="(item, index) in invoiceUrlList" :key="index" class="flex_center">
                  <img :src="jpg + item.imgUrl" />
                </li>
              </ul>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <div v-if="invBtnChange">
          <el-button class="footer_button2" round @click="applyInvDialog = false">取 消</el-button>
          <el-button class="footer_button1" type="primary" @click="submitInvForm('invPartner')">提 交</el-button>
        </div>
        <div v-else>
          <el-button class="footer_button1" type="primary" @click="applyInvDialog = false">关 闭</el-button>
        </div>
      </span>
    </el-dialog>

    <!-- 协助还款 -->
    <el-dialog title="协助还款" :visible.sync="applyRepayDialog" center class="dialog_width500 dialog_add_edit_form" :close-on-click-modal="false" :before-close="oncancelPay">
      <div class="add_invoice_message inner_common">
        <el-form :model="repayPartner" ref="repayPartner" :rules="repayRules">
          <el-form-item label="发票号码:">
            <el-input v-model="repayPartner.invoiceNo" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="发票金额:">
            <el-input v-model="repayPartner.invoiceAmount" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="发票日期:">
            <el-input v-model="repayPartner.invoiceDate" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="发票账期:">
            <el-input v-model="repayPartner.accPeriod" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="还款金额:">
            <el-input v-model="repayPartner.repaymentAmount" maxlength="20" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="还款时间:" prop="repaymentDate">
            <el-date-picker v-model="repayPartner.repaymentDate" :disabled="disableRepayInpt" :editable='false' prefix-icon="iconfont icon-riqi" type="date" value-format="yyyy-MM-dd"
              :picker-options="repaymentDateChoose">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="还款银行:" prop="repaymentBank">
            <el-input v-model="repayPartner.repaymentBank" maxlength="20" :disabled="disableRepayInpt"></el-input>
          </el-form-item>
          <el-form-item label="还款账号:" prop="repaymentBankNum">
            <el-input v-model="repayPartner.repaymentBankNum" maxlength="50" :disabled="disableRepayInpt"></el-input>
          </el-form-item>
          <el-form-item label="银行流水号:" prop="serialNumber">
            <el-input v-model="repayPartner.serialNumber" maxlength="50" :disabled="disableRepayInpt"></el-input>
          </el-form-item>
          <el-form-item label="上传还款凭证:" class="change_flex upload_item" prop="repaymentVoucher">
            <el-upload ref="uploadPayBackForm" :http-request="payBackUploadFile" :on-remove="payBackFileRemove" action="fakeaction" v-model="repayPartner.repaymentVoucher" class="upload_demo"
              :file-list="payBackFileList" list-type="picture" accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PBG" v-if="!disableRepayInpt">
              <div class="upload_box">
                <i class="el-icon-plus"></i>
              </div>
              <span slot="tip" class="el-upload__tip">支持JPG、PNG格式，大小不超过15M</span>
            </el-upload>
            <!-- <div v-if="repayPartner.repaymentVoucher" class="invoiceUrlClass">
              <ul>
                <li class="flex_center" style="position:relative">
                  <img :src="jpg + repayPartner.repaymentVoucher">
                </li>
              </ul>
            </div> -->
          </el-form-item>

        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="footer_button1" type="primary" @click="applyRepayDialog = false" v-if="disableRepayInpt">关闭</el-button>
        <template v-else>
          <el-button class="footer_button2" round @click="oncancelPay()">取 消</el-button>
          <el-button class="footer_button1" type="primary" @click="submitCorForm('repayPartner')">提 交</el-button>
        </template>
      </span>
    </el-dialog>
    <div v-show="detailDisplay">
      <!-- <div class="second_return_icon" @click="returnButton()">
            <i class="iconfont icon-fanhui" ></i>
            供应商详情
        </div> -->
      <Company ref="Company"></Company>
    </div>
  </div>
</template>
<script>
import { editSupplier, getSupplierList, getSupplierInvoiceList, confirmInvVerification, helpRepayment } from "@/api/coreEnterprise.js";
import { findCodeInfoByKey, uploads, getCompanyNameList } from "@/api/api.js";
import Company from '../../supplier/components/companyDetail.vue'
import { mixins1 } from "@/mixins/index";
import Page from "@/components/page";
import formValidation from "@/utils/rule.js";
export default {
  mixins: [mixins1],
  components: { Page, Company },
  computed:{
    repaymentDateChoose(){
      let self =this
      return{
        disabledDate(time){
          return time.getTime() < new Date(self.repayPartner.invoiceDate).getTime()+(3600*1000*24*30) ;
        }
      }
    }
  },
  data() {
    const valLendingBank = (rule, val, cb) => {
      if (!val) {
        return cb(new Error('请输入还款银行'))
      } else if (/^[\u4e00-\u9fa5a-zA-Z0-9]+$/.test(val) == false) {
        return cb(new Error("请输入20位中英文或数字"));
      }
      return cb()
    }
    return {
      refe: 0,
      supplierObj: {
        totalAccount: 0,
      },
      totalMoneyList: [
        {
          value: 0,
          name: "已申请发票金额",
        },
        {
          value: 0,
          name: "未申请发票金额",
        },
      ],
      supplierSerForm: {
        supplierId: "",
        supplierName: "",
        cooperYears: "",
        pageNo: 1,
        pageSize: 8,
      },
      supplerNo: '',
      //父表格
      treeTable: [],
      //父分页
      faTotalPage: 0,
      //子表格搜索条件
      invSearchForm: {
        invoiceNo: "",
        invoiceDate: "",
        invoiceAmount: "",
        invoicePayState: '',
        custNum: '',
        pageNo: 1,
        pageSize: 8
      },
      //子表格
      childTable: [],
      //子分页
      childTotalPage: 0,
      // 添加供应商
      dialogTit: "添加供应商",
      applySupplierDialog: false,
      addPartner: {
        id: "",
        supplierId: "",
        supplierName: "",
        supplierPerson: "",
        supplierPhone: "",
        supplierEmail: "",
        cooperYears: "",
        cooperDesc: "",
      },
      addRules: {
        supplierName: [
          { required: true, message: "请输入供应商名称", trigger: "blur" },
        ],
        supplierPerson: [
          { required: true, message: "请输入供应商联系人", trigger: "blur" },
        ],
        supplierPhone: [
          { required: true, message: "请输入供应商电话", trigger: "blur" },
          { validator: formValidation.validateMobile, trigger: "blur" }
        ],
        supplierEmail: [
          { required: true, message: "请输入供应商邮箱", trigger: "blur" },
          { validator: formValidation.validateEmail, trigger: "blur" }
        ],
        cooperYears: [
          { required: true, message: "请输入合作年限", trigger: "blur" },
        ]
      },
      disableInpt: false,
      options: [],
      //发票确认
      dialogInvTit: "发票确认",
      applyInvDialog: false,
      invPartner: {
        purchaseName: '',
        invoiceNo: "",
        invoiceAmount: "",
        newInvoiceAmount: "",
        invoiceDate: "",
        invoiceDateEnd: "",
        invoiceUrl: '',
        isConfirm: '',
        isApproval: '',
        isApprovalName: '',
        invoiceState: '',
        invoiceStateName: '',
        invoiceAccountDate: 40,
        paymentVoucher: '',
        paymentAmount: '',
        newPaymentAmount: '',
        paymentDate: '',
        isReceived: '',
        isInvoice: '',
      },
      invoiceUrlList: [],
      payUrlList: [],
      payFileList: [],
      payBackFileList: [],
      invRules: {
        isApproval: [
          { required: true, message: "请选择发票确认状态", trigger: "blur" },
        ],
        newPaymentAmount: [
          { required: true, message: "请填写付款金额", trigger: "blur" },
        ],
        paymentDate: [
          { required: true, message: "请选择付款日期", trigger: "blur" },
        ],
        paymentVoucher: [
          { required: true, message: "请上传图片", trigger: "blur" },
        ],
      },
      disableInvInpt: true,
      invBtnChange: false, //false:发票详情页,true:发票确认页
      invconfirmOptions: [],
      //协助还款
      applyRepayDialog: false,
      disableRepayInpt: true,
      repayPartner: {
        invoiceNo: '',
        invoiceAmount: "",
        invoiceDate: '',
        accPeriod: "",
        repaymentVoucher: "",
        repaymentDate: '',
        repaymentBank: '',
        repaymentAmount: '',
        repaymentBankNum: '',
        serialNumber: '',
      },
      repayRules: {
        repaymentDate: [{ required: true, message: "请填写还款时间", trigger: "blur" }],
        repaymentBank: [{ required: true, validator: valLendingBank, trigger: "blur" }],
        repaymentBankNum: [{ required: true, message: "请填写还款账号", trigger: "blur" }],
        serialNumber: [{ required: true, message: "请填写银行流水号", trigger: "blur" }],
        repaymentVoucher: [
          { required: true, message: "请上传图片", trigger: "blur" },
        ],

      },
      supplierName: [],
      detailDisplay: false,
      supplierDiv: true,
      dataname: [],
      isConfirmOptions: [
        {
          value: "1",
          label: "认可",
        },
        {
          value: "0",
          label: "未认可",
        },
      ],
      isPayDisplay: false,
      hasNoPay: true,
      hasApproval: false,
    }
  },
  created() {
    this.tableWidth()
    this.resizeFn()
    this.getSupplerList()
    this.corOptionsList()
  },
  methods: {
   // 表格展开或关闭时,清空检索条件
   onExpandChange(){
      this.$refs.invSearchForm.resetFields()
      this.invSearchForm.invoiceDate = ''
      this.invSearchForm.pageNo = 1
    },
    
    searchInput1(val) {
      val = val.replace(/[^\d]/g, '')
      this.supplierSerForm.supplierId = val
    },
    riskEchart(fontSize, echartsWidth) {
      let that = this;
      this.myChart = this.$echarts.init(this.$refs.totalMoneyEchart);
      const option = {
        tooltip: {
          trigger: "axis",
          extraCssText:
            "box-shadow: 0px 4px 12px 0px rgba(7, 72, 64, 0.29);width:140px;height:66px",
          axisPointer: {
            type: "none",
            label: {
              lineHeight: 30,
            },
          },
          formatter: function (params) {
            var tar = params[1];
            tar.value = that.filter(tar.value);
            if (tar.name == "未申请发票金额") {
              return (
                '<span style="color:#7c969b;">' +
                tar.name +
                "<br/>" +
                tar.value +
                "元" +
                "</span>"
              );
            }
            if (tar.name == "已申请发票金额") {
              return (
                '<span style="color:#339c9b;">' +
                tar.name +
                "<br/>" +
                tar.value +
                "元" +
                "</span>"
              );
            }
          },
        },
        grid: {
          top: '20%',
          left: '11%',
          right: '3%',
          bottom: '20%'
        },
        xAxis: {
          type: "value",
          min: 0,
          // splitNumber: 10,
          axisLine: {
            show: true,
            lineStyle: {
              width: 1,
              color: "#92A2BC",
            },
          },
          axisLabel: {
            align: "right",
            fontSize: fontSize,
          },
        },
        yAxis: {
          type: "category",
          data: [
            {
              value: this.totalMoneyList[1].name,
              textStyle: {
                color: "#7c969b", //坐标值得具体的颜色
              },
            },
            {
              value: this.totalMoneyList[0].name,
              textStyle: {
                color: "#339C9B", //坐标值得具体的颜色
              },
            },
          ],
          axisLine: {
            lineStyle: {
              width: 1,
              color: "#92A2BC", //坐标线的颜色
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            align: "right",
            fontSize: fontSize,
          },
        },
        series: [
          {
            type: "bar",
            stack: "Total",
            data: [0, 0],
          },
          {
            type: "bar",
            stack: "Total",
            data: [
              {
                value: this.totalMoneyList[1].value,
                itemStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: "#cfdee1",
                      },
                      {
                        offset: 1,
                        color: "#7c969b",
                      },
                    ],
                    globalCoord: false,
                  },
                  shadowBlur: 10,
                  shadowColor: "rgba(45, 62, 60, 0.2)",
                  shadowOffsetX: 6,
                  shadowOffsetY: 10,
                },
              },
              {
                value: this.totalMoneyList[0].value,
                itemStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: "#7be0df",
                      },
                      {
                        offset: 1,
                        color: "#339c9b",
                      },
                    ],
                    globalCoord: false,
                  },
                  shadowBlur: 10,
                  shadowColor: "rgba(12, 79, 71, 0.2)",
                  shadowOffsetX: 6,
                  shadowOffsetY: 10,
                },
              },
            ],
            barWidth: echartsWidth,
            itemStyle: {
              normal: {
                borderRadius: [0, 100, 100, 0],
              },
            },
          },
        ],
      };
      this.myChart.setOption(option, true);
      this.myChart.resize();
    },
    resizeFn() {
      if (document.body.clientWidth > 1664) {
        this.fontSize = 14
        this.echartsWidth = 36
        this.width30 = 30
        this.suplWidth = 190
        this.lastWidth = 130
        this.suplNoWidth = 170
      } else {
        this.fontSize = 10
        this.echartsWidth = 24
        this.width30 = 20
        this.suplWidth = 110
        this.lastWidth = 110
        this.suplNoWidth = 140
      }
      this.$nextTick(function () {
        this.riskEchart(this.fontSize, this.echartsWidth);
      });
    },


    //展开当前关闭其他展开表格
    onClickExpansion(row) {
      let list = this.treeTable
      this.supplerNo = row.supplierId

      list.map((item) => {
        if (row.id != item.id) {
          item.isExpand = false;
          this.$refs.treeTable.toggleRowExpansion(item, false);
        }
      });
      row.isExpand = !row.isExpand;
      this.$refs.treeTable.toggleRowExpansion(row)
      if (row.isExpand) {
        this.childTable = []
        this.getInvList()
      }

    },

    //获取供应商列表
    async getSupplerList() {
      const res = await getSupplierList('pageNo=' + this.supplierSerForm.pageNo + '&pageSize=' + this.supplierSerForm.pageSize
        + '&supplierName=' + this.supplierSerForm.supplierName + '&cooperYears=' + this.supplierSerForm.cooperYears + '&supplierId=' + this.supplierSerForm.supplierId)
      if (res && res.code == 200) {
        let obj = res
        this.treeTable = obj.data.records
        this.faTotalPage = obj.data.total
        this.$nextTick(function () {
          this.supplierObj.totalAccount = obj.invoiceAllAmount ? obj.invoiceAllAmount : 0
          this.totalMoneyList[0].value = parseFloat(obj.appliedAmount)
          this.totalMoneyList[1].value = parseFloat(obj.applyAmount)
          this.riskEchart(this.fontSize, this.echartsWidth);
        });

      }
    },
    searchForm() {
      this.page.pageNo = 1
      this.getSupplerList()
    },
    //清空供应商搜索条件
    clearSupSearch() {
      this.supplierSerForm.supplierId = ''
      this.supplierSerForm.supplierName = ''
      this.supplierSerForm.cooperYears = ''
      this.supplierSerForm.pageNo = 1
      this.getSupplerList()
    },

    //清空发票搜索条件
    clearInvSearch() {
      this.$refs.invSearchForm.resetFields()
      // this.invSearchForm.invoiceNo = ''
      this.invSearchForm.invoiceDate = ''
      // this.invSearchForm.invoiceAmount = ''
      this.invSearchForm.pageNo = 1
      this.getInvList()
    },
    //查询发票列表
    async getInvList() {
      if (this.invSearchForm.invoiceDate) {
        this.startDate = this.invSearchForm.invoiceDate.slice(',')[0]
        this.endDate = this.invSearchForm.invoiceDate.slice(',')[1]
      } else {
        this.startDate = ''
        this.endDate = ''
      }
      if (this.invSearchForminvoicePayState == undefined) {
        this.invSearchForminvoicePayState = ''
      }
      const res = await getSupplierInvoiceList('pageNo=' + this.invSearchForm.pageNo + '&pageSize=' + this.invSearchForm.pageSize
        + '&custNum=' + this.supplerNo + '&invoiceAmount=' + this.invSearchForm.invoiceAmount + '&startDate='
        + this.startDate + '&endDate=' + this.endDate + '&invoiceNo=' + this.invSearchForm.invoiceNo + '&invoiceState=' + this.invSearchForm.invoicePayState)
      if (res && res.code == 200) {
        let obj = res.data
        this.childTable = obj.records
        this.childTotalPage = obj.total
      }
    },
    // 供应商名称模糊搜索
    handleSelect(item) {
      this.addPartner.supplierName = item.company_name
      // this.addPartner.supplierName= item.company_name
    },
    //获取供应商名称
    async getCompanyNameList() {
      if (this.addPartner.supplierName) {
        let data = await getCompanyNameList('comName=' + this.addPartner.supplierName)
        this.dataname = data.data
        if (this.dataname) {
          this.dataname.forEach((item, index) => {
            this.dataname[index].value = item.company_name
          });
        }
      }
      else {
        this.dataname = []
      }
    },
    querySearch(queryString, cb) {
      this.getCompanyNameList().then(() => {
        var restaurants = this.dataname;
        var results = []
        if (restaurants) {
          results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
        }
        // results = queryString ? restaurants : [];
        cb(results);
      })
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase())!==-1 );
      };
    },
    //父分页方法
    onPageChange(event) {
      this.supplierSerForm.pageNo = event
      this.supplierSerForm.pageSize = 8
      this.getSupplerList()
    },
    //子分页方法
    onChildPageChange(event) {
      this.invSearchForm.pageNo = event
      this.invSearchForm.pageSize = 8
      this.getInvList()
    },
    //添加供应商
    async dialogAddPartner() {
      this.applySupplierDialog = true;
      this.disableInpt = false;
      this.$nextTick(() => {
        this.$refs.addPartner.resetFields()
        delete this.addPartner.id
      })

    },

    //提交编辑添加供应商
    async editSupplier() {
      const res = await editSupplier(this.addPartner)
      if (res && res.code == 200) {
        this.getSupplerList()
        this.applySupplierDialog = false;
      }
      else {
        this.$message.error(res.msg);
        delete this.addPartner.id
      }
    },

    //提交编辑添加供应商
    submitSupplierForm(addPartner) {
      this.$refs[addPartner].validate((valid) => {
        if (valid) {
          this.editSupplier()
        } else {
          return false;
        }
      });
    },
    // 编辑供应商
    dialogEditPartner(item) {
      this.dialogTit = "编辑供应商";
      this.applySupplierDialog = true;
      this.disableInpt = false;
      console.log(item)
      this.$nextTick(() => {
        this.addPartner = JSON.parse(JSON.stringify(item));
      })
    },
    //取消添加客户
    cancelSupplierDialog() {
      this.$refs.addPartner.resetFields()
      this.applySupplierDialog = false
    },

    //上传付款
    payUploadFile(params) {
      const paymentVoucher = this.invPartner.paymentVoucher
      if (paymentVoucher == null || paymentVoucher == '') {
        const file = params.file
        let form = new FormData();
        form.append("files", file);
        console.log('1111(fo', file)
        uploads(form).then(res => {
          this.invPartner.paymentVoucher = res[0]
          this.$refs.invPartner.validateField("paymentVoucher")
        })
      }
    },
    payFileRemove() {
      this.invPartner.paymentVoucher = ''
      this.payFileList = []
    },
    // deleteUpload() {
    //     this.repayPartner.paymentVoucher = ''
    // },
    //上传还款凭证
    payBackUploadFile(params) {
      if (this.repayPartner.repaymentVoucher == '' || this.repayPartner.repaymentVoucher == null) {
        const file = params.file
        let form = new FormData();
        form.append("files", file);
        uploads(form).then(res => {
          this.repayPartner.repaymentVoucher = res[0]
          this.$refs.repayPartner.validateField("repaymentVoucher")
        })
      }
    },
    payBackFileRemove(file,fileList) {
      console.log(this.payBackFileList,file,fileList)
      // this.repayPartner.repaymentVoucher = ''
      this.payBackFileList = fileList
    },

    //付款金额过滤
    inputPayFocus(value) {
      if (value) {
        this.invPartner.paymentAmount = this.filterOut(value)
      }
    },
    inputPayBlur(value) {
      this.invPartner.paymentAmount = value
      console.log('pay', this.invPartner.paymentAmount)
      this.invPartner.newPaymentAmount = this.filter(value)

    },
    //修改发票付款状态
    changePayStatus() {
      const invoiceState = this.invPartner.invoiceState
      this.refe++
      if (invoiceState == 1) {
        this.isPayDisplay = true
      }
      else {
        this.isPayDisplay = false
        this.invPartner.paymentAmount = ''
        this.invPartner.paymentDate = ''
        this.invPartner.paymentVoucher = ''
      }
    },
    //发票确认弹框
    invConfirmDialog(item) {
      this.dialogInvTit = "发票确认";
      this.invBtnChange = true;
      this.isPayDisplay = false
      this.invPartner = JSON.parse(JSON.stringify(item));
      const invoiceState = item.invoiceState
      if (invoiceState == '1') {
        this.hasNoPay = false
        this.invPartner.invoiceStateName = '已付款'
      }
      else {
        this.hasNoPay = true
        this.invPartner.invoiceStateName = '未付款'
      }
      //写死
      this.invPartner.invoiceAccountDate = 40

      this.invPartner.newInvoiceAmount = this.filter(this.invPartner.invoiceAmount)

      //  this.invPartner.newPaymentAmount=this.filter(this.invPartner.newPaymentAmount)

      this.invPartner.isApprovalName = this.invPartner.isApproval == 0 ? "不认可" : '认可'
      this.invPartner.isApproval == 0 ? this.hasApproval = false : this.hasApproval = true


      this.invoiceUrlList = []
      this.payUrlList = []
      if (this.invPartner.invoiceUrl) {
        let obj = {}
        obj.imgUrl = this.invPartner.invoiceUrl
        this.invoiceUrlList.push(obj)
      }
      if (this.invPartner.paymentVoucher) {
        let obj = {}
        obj.imgUrl = this.invPartner.paymentVoucher
        this.payUrlList.push(obj)
      }
      this.invPartner.isApproval = this.invPartner.isApproval + ''

      this.applyInvDialog = true;
    },
    //提交按钮确认发票
    submitInvForm(invPartner) {
      this.$refs[invPartner].validate((valid) => {
        console.log(valid)
        if (valid) {
          console.log('invPartner', this.invPartner)
          this.confirmInvForm()
        }
        else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 提交发票确认表单
    async confirmInvForm() {
      this.invPartner.invoiceAmount = Number(this.invPartner.invoiceAmount)
      // this.invPartner.isApproval = Number(this.invPartner.isApproval)
      let res = await confirmInvVerification(this.invPartner)
      if (res && res.code == 200) {
        let obj = res.data
        this.applyInvDialog = false;
        this.getInvList()
      } else {
        this.$message.error(res.msg)
      }
    },

    //发票详情
    dialogInvDetail(item) {
      this.dialogInvTit = "发票详情";
      this.invBtnChange = false;
      this.invPartner = item;
      this.applyInvDialog = true;
      this.invoiceUrlList = []
      this.payUrlList = []
      if (this.invPartner.invoiceUrl) {
        let obj = {}
        obj.imgUrl = this.invPartner.invoiceUrl
        this.invoiceUrlList.push(obj)
      }
      if (this.invPartner.paymentVoucher) {
        let obj = {}
        obj.imgUrl = this.invPartner.paymentVoucher
        this.payUrlList.push(obj)
      }
      this.invPartner.isApprovalName = this.invPartner.isApproval == 0 ? "不认可" : '认可'
      this.invPartner.invoiceStateName = this.invPartner.invoiceState == '0' ? "未付款" : '已付款'
      this.invPartner.newInvoiceAmount = this.filter(this.invPartner.invoiceAmount)
      this.invPartner.paymentAmount = this.filter(this.invPartner.paymentAmount)
      this.invPartner.isApprovalName = this.invPartner.isApproval == 0 ? "不认可" : '认可'
      this.hasApproval = true

      if (this.invPartner.invoiceState == 1) {
        this.hasNoPay = false
      }
    },

    //协助返款
    dialogRepayment(item) {
      this.applyRepayDialog = true;

      this.repayPartner = item
      this.repayPartner.assistRepayment = item.assistRepayment.toString()
      this.repayPartner.invoiceAmount = this.filter(this.repayPartner.invoiceAmount)
      this.repayPartner.repaymentAmount = this.filter(this.repayPartner.repaymentAmount)
      this.payBackFileList = []

      if (item.assistRepayment == 1) {
        this.disableRepayInpt = true
      } else {
        this.disableRepayInpt = false
      }
      if (this.repayPartner.repaymentVoucher) {
        let obj = {}
        obj.imgUrl = this.repayPartner.repaymentVoucher
        this.payBackFileList.push(obj)
      }

    },
    //提交还款
    async helpRepayment() {
      console.log(this.repayPartner)
      this.repayPartner.invoiceAmount = this.filterOut(this.repayPartner.invoiceAmount)
      this.repayPartner.repaymentAmount = this.filterOut(this.repayPartner.repaymentAmount)
      let res = await helpRepayment(this.repayPartner)
      if (res && res.code == 200) {
        this.applyRepayDialog = false;
      }
    },
    oncancelPay(){
      this.applyRepayDialog=false
      this.payBackFileList = []
      this.$refs.repayPartner.resetFields()
    },
    submitCorForm(repayPartner) {
      this.$refs[repayPartner].validate(async (valid) => {
        console.log(valid)
        if (valid) {
          this.helpRepayment()
        }
        else {
          return false;
        }
      });
    },

    //字段合作年限
    async corOptionsList() {
      let res = await findCodeInfoByKey('ms=7')
      this.options = res.data
      let inv = await findCodeInfoByKey('ms=16')
      this.invconfirmOptions = inv.data
      let res2 = await findCodeInfoByKey('ms=3')
      this.isConfirmOptions = res2.data
    },

    detailIndex(row) {
      this.$refs.Company.getCompany(row.supplierName)
      this.supplierDiv = false
      this.detailDisplay = true
    },
    // 返回上一页
    returnButton() {
      this.detailDisplay = false
      this.supplierDiv = true
      window.scrollTo(0, 0)
    },
    //下拉框校验
    selChangeCooper(val) {
      if (val) {
        this.$refs.addPartner.validateField("cooperYears")
      }
    }
  },
};
</script>
<style scoped>
  .el-width.el-tooltip__popper {
    width: 92px !important;
  }
  ::v-deep .select_class_sep .el-form-item__label {
    min-width: 97px !important;
  }
  ::v-deep .select_class_sep.el-form-item {
    margin-bottom: 20px !important;
  }
  ::v-deep .sub_search_condition .el-form-item {
    margin-right: 55px;
  }
  .sub_search_condition .search_boxs {
    width: 120px;
  }
  ::v-deep .special_select_class .el-input {
    width: 280px !important;
  }
  ::v-deep .el-badge {
    color: #fb6476;
  }
  ::v-deep .el-badge__content.is-fixed {
    top: 6px;
  }
  ::v-deep .el-badge__content.is-fixed.is-dot {
    right: -1px;
  }
  ::v-deep .el-badge__content {
    background-color: #fb6476;
  }
  .apply_box_top {
    display: flex;
    padding-top: 10px;
    margin-bottom: 24px;
  }
  .apply_box_top_left {
    width: 340px;
    height: 176px;
    margin-right: 60px;
    padding: 20px;
    background: #339c9b;
    box-shadow: 0px 10px 20px 0px rgba(47, 160, 159, 0.23);
    border-radius: 14px;
  }
  .apply_box_total {
    display: flex;
    align-items: center;
    width: 300px;
    height: 136px;
    padding-top: 20px;
    padding-bottom: 24px;
    padding-left: 16px;
    background: rgba(237, 242, 245, 0.2);
    box-shadow: 0px 4px 8px 0px rgba(0, 102, 101, 0.23);
    border-radius: 14px;
  }
  .apply_box_total i {
    flex: 1;
    margin-right: 30px;
    color: #ffffff;
    font-size: 34px;
  }
  .apply_box_top_right {
    flex: 1;
  }
  .echarts_box {
    height: 250px;
    margin-top: -40px;
  }
  .table_box_top_title {
    flex: 1;
  }
  /* .search_condition{
                                    flex: 1;
                                  } */
  .search_condition .el-form-item:last-child {
    margin-right: 0;
  }
  .sub_search_condition .el-input,
  .search_condition >>> .el-date-editor--daterange.el-input__inner {
    width: 280px;
  }
  .noLink {
    color: #606266;
    text-decoration: none;
    width: 100%;
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
  }
  .dialog_width500 >>> .el-form-item__label {
    flex: 1;
    /* min-width: 102px; */
  }
  .invimg_list li {
    float: left;
    width: 60px;
    height: 60px;
    margin-right: 12px;
    margin-bottom: 5px;
    background: #f7fafc;
    border-radius: 4px;
    border: 1px solid #e1e7ea;
  }
  .invimg_list img:hover {
    position: absolute;
    bottom: -1px;
    left: 0px;
    cursor: pointer;
    transform: scale(1);
    box-shadow: 0px 4px 8px 0px rgba(12, 79, 71, 0.2);
    border-radius: 4px;
  }
  .no_border >>> .el-input__inner {
    border: none;
    background: #ffffff;
    color: #162747;
    padding-left: 0;
  }
  .el-radio-group {
    margin-top: 10px;
  }
  .el-radio {
    margin-right: 40px;
  }
  .sub_search_condition {
    background: #edf2f5;
    border-radius: 10px;
    padding: 17px 20px;
    flex: 1;
  }
  .dialog_width500 .change_width >>> .el-form-item__label {
    min-width: 126px;
  }
  .no_edit_width >>> .el-form-item__label {
    min-width: 128px;
  }
  .dialog_width500 .change_width >>> .el-form-item__content {
    line-height: 40px;
  }
  .dialog_width500
    .change_padding
    .is-success
    .el-date-editor--date:hover
    >>> .el-input__prefix {
    display: none;
  }
  .dialog_width500 .change_width >>> .el-radio-group {
    margin-top: 8px;
  }
  .dialog_width500 >>> .el-input__prefix {
    right: 10px !important;
    left: auto;
  }
  .upload_box {
    width: 60px;
    height: 60px;
    margin-right: 6px;
    background: #f7fafc;
    border-radius: 4px;
    border: 1px solid #e1e7ea;
  }
  .upload_box i {
    font-size: 20px;
    line-height: 60px;
    color: #7c969b;
  }
  .upload_box i:focus {
    color: #7c969b;
  }
  .invimg_list li {
    float: left;
    width: 60px;
    height: 60px;
    margin-right: 12px;
    margin-bottom: 5px;
    background: #f7fafc;
    border-radius: 4px;
    border: 1px solid #e1e7ea;
  }
  .choose_active {
    color: #162747;
    background: #ffffff;
  }
  .no_border >>> .el-input__inner {
    border: none;
    background: #ffffff;
    color: #162747;
    padding-left: 0;
  }
  .upload_demo >>> .el-upload-list {
    margin-top: 0;
    width: 350px;
  }
  .upload_demo >>> .el-upload__tip {
    right: 75px;
  }
  .invoiceUrlClass {
    float: left;
    width: 60px;
    height: 62px;
    padding: 0;
    background: #f7fafc;
    border-radius: 4px;
    border: 1px solid #e1e7ea;
    overflow: visible;
  }
  .invoiceUrlClass img {
    width: 60px;
    height: 60px;
    margin-left: 0;
    z-index: 0;
  }
  .hInpt {
    display: none;
  }
  .table_oper .gray_color {
    color: #E1E7EA !important;
    cursor: not-allowed !important;
  }
  @media screen and (max-width: 1664px) {
    ::v-deep .select_class_sep .el-form-item__label {
      min-width: 80px !important;
    }
    ::v-deep .select_class_sep.el-form-item {
      margin-bottom: 13px !important;
    }
    ::v-deep .sub_search_condition .el-form-item {
      /* margin-right: 55px; */
    }
    .sub_search_condition .search_boxs {
      width: 80px;
    }
    ::v-deep .special_select_class .el-input {
      width: 200px !important;
    }
    .apply_box_top {
      padding-top: 6px;
      margin-bottom: 16px;
    }
    .apply_box_top_left {
      width: 226px;
      height: 117px;
      margin-right: 40px;
      padding: 13px;
      box-shadow: 0px 6px 13px 0px rgba(47, 160, 159, 0.23);
      border-radius: 10px;
    }
    .apply_box_total {
      width: 200px;
      height: 90px;
      padding-top: 13px;
      padding-bottom: 10px;
      padding-left: 10px;
      box-shadow: 0px 4px 8px 0px rgba(0, 102, 101, 0.23);
      border-radius: 10px;
    }
    .apply_box_total i {
      flex: 1;
      margin-right: 20px;
      font-size: 22px;
    }
    .echarts_box {
      height: 166px;
      margin-top: -26px;
    }

    .sub_search_condition >>> .el-input,
    .search_condition >>> .el-date-editor--daterange.el-input__inner {
      width: 200px;
      height: 20px;
    }
    .search_condition >>> .el-form-item {
      margin-right: 5px;
    }
    .invimg_list li {
      width: 40px;
      height: 40px;
      margin-right: 8px;
      margin-bottom: 3px;
      border-radius: 2px;
    }
    .el-radio-group {
      margin-top: 7px;
    }
    .el-radio {
      margin-right: 30px;
    }
    .inner_common .change_width >>> .el-form-item__content {
      line-height: 30px;
    }
    .inner_common .change_width >>> .el-form-item__label {
      min-width: 85px;
    }
    .inner_common .no_edit_width >>> .el-form-item__label {
      min-width: 106px;
    }
    .dialog_width500 .el-input__prefix {
      right: 5px;
    }
    .upload_box {
      width: 40px;
      height: 40px;
      margin-right: 2px;
      background: #f7fafc;
      border-radius: 4px;
      border: 1px solid #e1e7ea;
    }
    .upload_box i {
      font-size: 14px;
      line-height: 40px;
      color: #7c969b;
    }
    .invimg_list li {
      width: 40px;
      height: 40px;
      margin-right: 8px;
      margin-bottom: 3px;
      border-radius: 2px;
    }
    .upload_demo >>> .el-upload-list {
      margin-top: 0;
      width: 200px;
    }
    .upload_demo >>> .el-upload__tip {
      right: -24px;
    }
    .invoiceUrlClass {
      float: left;
      width: 40px;
      height: 42px;
      margin-right: 14px;
      padding: 0;
      background: #f7fafc;
      border-radius: 3px;
      border: 1px solid #e1e7ea;
      overflow: visible;
    }
    .invoiceUrlClass img {
      width: 40px;
      height: 40px;
      margin-left: 0;
      z-index: 0;
    }
  }
</style>